<div class="container statute-container mt-5 mb-5">
    <div class="col-md-10">
        <h1 class="mb-4">Ogólne Warunki Sprzedaży i Dostawy</h1>
        <div class="statute-point">
            <h2>1. Wprowadzenie</h2>
            <p>Niniejsze Ogólne Warunki Sprzedaży i Dostawy (zwane dalej "OWS&D") określają warunki, na jakich Firma Handlowo Usługowa Armes, z siedzibą przy ulicy Bartosza Głowackiego 60a, 44-100 Gliwice, NIP: 9690181749, REGON: 240423507 (zwana dalej "Firmą") oferuje swoje produkty i usługi klientom.</p>
        </div>
        <div class="statute-point">
            <h2>2. Zapytanie ofertowe</h2>
            <p>2.1. Wszystkie zlecenia i zamówienia realizowane są wyłącznie na podstawie uprzednich zapytań ofertowych
                dostarczonych przez klienta.</p>
            <p>2.2. Klient zobowiązany jest do dostarczenia dokładnych i kompletnych informacji w zapytaniu ofertowym.</p>
        </div>
        <div class="statute-point">
            <h2>3. Oferta</h2>
            <p>3.1. Firma przygotowuje ofertę handlową na podstawie zapytania ofertowego klienta.</p>
            <p>3.2. Oferta handlowa jest ważna przez określony w niej termin.</p>
            <p>3.3. Zamówienie klienta zostaje potwierdzone i zrealizowane przez Firmę po akceptacji oferty handlowej.</p>
        </div>
        <div class="statute-point">
            <h2>4. Cena i Płatność
            </h2>
            <p>4.1. Cena produktów lub usług jest określana w ofercie handlowej.</p>
            <p>4.2. Warunki płatności są ustalane indywidualnie zgodnie z ofertą handlową.</p>
        </div>
        <div class="statute-point">
            <h2>5. Dostawa</h2>
            <p>5.1. Termin dostawy jest określany w ofercie handlowej.</p>
            <p>5.2. Koszty dostawy są uzgadniane z klientem i uwzględniane w ofercie handlowej.</p>
            <p>5.3. Firma dokłada wszelkich starań, aby termin dostawy był dotrzymywany, jednakże czas dostawy może ulec
                zmianie w przypadku sił wyższych lub innych okoliczności niezależnych od Firmy.</p>
        </div>
        <div class="statute-point">
            <h2>6. Gwarancja i Reklamacje</h2>
            <p>6.1. Firma zapewnia gwarancję na produkty zgodnie z warunkami określonymi w ofercie handlowej i zgodnie z
                obowiązującymi przepisami prawa.</p>
            <p>6.2. Klient ma prawo zgłosić reklamację w przypadku produktów wadliwych lub niezgodnych z umową zgodnie z
                obowiązującymi przepisami.</p>
        </div>
        <div class="statute-point">
            <h2>7. Prawo Własności Intelektualnej</h2>
            <p>7.1. Firma zastrzega sobie prawa do własności intelektualnej, w tym praw autorskich i znaków towarowych,
                związanego z produktami i usługami oferowanymi klientowi.</p>
        </div>
        <div class="statute-point">
            <h2>8. Postanowienia Końcowe</h2>
            <p>8.1. Wszelkie spory mogące wynikać z umów kupna-sprzedaży zawieranych z Firmą podlegają rozstrzygnięciu przez sąd właściwy dla siedziby Firmy.</p>
            <p>8.2. W sprawach nie uregulowanych niniejszymi OWS&D, ani inną obowiązującą Umową zawartą pomiędzy Firmą a Klientem, stosuje się odpowiednio przepisy Kodeksu Cywilnego.</p>
            <p>8.3. Firma zastrzega sobie prawo do zmiany niniejszych OWS&D zgodnie z obowiązującymi przepisami.</p>
        </div>
        <div class="statute-modified-date">
            Ostatnia aktualizacja: [05.10.2023]
        </div>
    </div>
</div>