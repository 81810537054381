<div class="container article-container">
    <div class="col-md-10">
        <article>

            <div class="title-container mb-4">
                <div class="spacer"></div>
                <div class="title-text">
                    <h1>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_HEADING' | translate}}</h1>
                </div>
                <div class="spacer"></div>
            </div>

            <div class="mb-4">
                <p>
                    {{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS' | translate}}
                </p>
            </div>

            <div class="article-paragraph row mb-4 img-right">
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_1_HEADING' | translate}}</h2>
                    <p>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_1' | translate}}</p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty-budynkowe/MSH.webp" alt="Przepust ścienny z uszczelnieniem wewnętrznym"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_IMG_CAPTION_1' | translate}}
                        </p>
                        <p>
                            MSH PolySafe GV2
                        </p>
                    </span>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty-budynkowe/ADS200.webp" alt="Kombinacja przepustów do rur wodociągowych "
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_IMG_CAPTION_2' | translate}}
                        </p>
                        <p>ROKA WF-TE-ARO DN100/110 BL960</p>
                    </span>
                </div>
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_2_HEADING' | translate}}</h2>
                    <p>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_2' | translate}}</p>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-right">
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_3_HEADING' | translate}}</h2>
                    <p>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_3' | translate}}</p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty-budynkowe/AT110.webp" alt="Odpływ"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_IMG_CAPTION_3' | translate}}
                        </p>
                        <p>
                            AT110
                        </p>
                    </span>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty-budynkowe/B1_ESHPolySafe.webp" alt="Pojedynczy przepust do budynków"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_IMG_CAPTION_4' | translate}}
                        </p>
                        <p>ESH PolySafe BHP</p>
                    </span>
                </div>
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_4_HEADING' | translate}}</h2>
                    <p>{{'BUILDING_PENETRATIONS_COMPONENT.BUILDING_PENETRATIONS_PARAGRAPH_4' | translate}}</p>
                </div>
            </div>

            <app-catlog-link></app-catlog-link>

        </article>
    </div>
</div>
