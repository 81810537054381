<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 offer">
                <div class="content-wraper">
                    <span>{{ 'FOOTER.OFFER_SECTION_TITLE' | translate }}</span>
                    <ul class="list-items offer-links">
                        <li>                            
                            <a href="/oferta/przepusty-kablowe" routerLink="/oferta/przepusty-kablowe" class="link-item">{{ 'FOOTER.OFFER_LINK_1' | translate }}</a>
                        </li>
                        <li>
                            <a href="/oferta/rury-przepustowe" routerLink="/oferta/rury-przepustowe" class="link-item">{{ 'FOOTER.OFFER_LINK_2' | translate }}</a>
                        </li>
                        <li>
                            <a href="/oferta/przepusty-uziemiajace" routerLink="/oferta/przepusty-uziemiajace" class="link-item">{{ 'FOOTER.OFFER_LINK_3' | translate }}</a>
                        </li>
                        <li>
                            <a href="/oferta/przepusty-budynkowe" routerLink="/oferta/przepusty-budynkowe" class="link-item">{{ 'FOOTER.OFFER_LINK_4' | translate }}</a>
                        </li>
                        <li>
                            <a href="/oferta/inne" routerLink="/oferta/inne" class="link-item">{{ 'FOOTER.OFFER_LINK_5' | translate }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 contact">
                <div class="content-wraper">
                    <span>{{ 'FOOTER.CONTACT_SECTION_TITLE' | translate }}</span>
                    <ul class="list-items" style="text-align: center;">
                        <li>{{ 'FOOTER.CONTACT_PHONE' | translate }}</li>
                        <li>{{ 'FOOTER.CONTACT_EMAIL' | translate }}</li>
                        <br>
                        <li><a href="/ows" routerLink="/ows" class="link-item">{{ 'FOOTER.CONTACT_TERMS_LINK' | translate }}</a></li>
                        <li><a href="/polityka-prywatnosci" routerLink="/polityka-prywatnosci" class="link-item">{{ 'FOOTER.CONTACT_PRIVACY_LINK' | translate }}</a></li>
                    </ul>
                </div>     
            </div>
            <div class="col-md-4 company-data" >
                <div class="content-wraper">
                    <ul>
                        <li class="li-title">{{ 'FOOTER.COMPANY_TITLE' | translate }}</li>
                        <li class="company-details">{{ 'FOOTER.COMPANY_ADDRESS' | translate }}</li>
                        <li class="company-details">{{ 'FOOTER.COMPANY_CITY' | translate }}</li>
                        <li class="company-details">{{ 'FOOTER.COMPANY_NIP' | translate }}</li>
                        <li class="company-details">{{ 'FOOTER.COMPANY_REGON' | translate }}</li>
                        <li class="company-details"><a href="https://www.armes.pl" style="color: inherit;">{{ 'FOOTER.COMPANY_WEBSITE' | translate }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
