import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToFragmentService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }


  scrollToStart() {
    if (isPlatformBrowser(this.platformId)) {
      const element = document.getElementById('start');
      if (element) {
        // element.scrollIntoView({ behavior: 'smooth' });
        element.scroll({ top: 0, left: 0, behavior: 'smooth' });
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    }

  }
}
