import { AfterViewInit, Component } from '@angular/core';
import { ScrollToFragmentService } from 'src/app/services/scroll-to-fragment.service';

@Component({
  selector: 'app-cable-penetrations',
  templateUrl: './cable-penetrations.component.html',
  styleUrls: ['./cable-penetrations.component.scss']
})
export class CablePenetrationsComponent implements AfterViewInit { 

  constructor(private scrollService: ScrollToFragmentService){
    
  }


  ngAfterViewInit(): void {

    this.scrollService.scrollToStart();

  }

}
