import { AfterViewInit, Component } from '@angular/core';
import { ScrollToFragmentService } from 'src/app/services/scroll-to-fragment.service';

@Component({
  selector: 'app-building-penetrations',
  templateUrl: './building-penetrations.component.html',
  styleUrls: ['./building-penetrations.component.scss']
})
export class BuildingPenetrationsComponent implements AfterViewInit{

 
  constructor(private scrollService: ScrollToFragmentService){
    
  }
    
  ngAfterViewInit(): void {
    this.scrollService.scrollToStart();
  }


}
