import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, tap, throwError } from 'rxjs';
import { CatalogService } from 'src/app/services/catalog.service';


@Component({
  selector: 'app-contact-mailform',
  templateUrl: './contact-mailform.component.html',
  styleUrls: ['./contact-mailform.component.scss']
})
export class ContactMailformComponent implements OnInit {

  messageSend = false;
  errorWhileSending = false;

  endpoint : string = 'https://www.przepusty-kablowe.pl/sendEmail.php';

  catalogData: { name: string; index: number }[] = [];

  constructor(private catalogService: CatalogService, private http: HttpClient) { }

  ngOnInit(): void {
    this.catalogData = this.catalogService.getCatalogData();
  }


  contactRequest = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    name: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
    statutes: new FormControl(false, [Validators.pattern('true')])
  })



  onSubmit() {
    let email = this.contactRequest.value.email as string;
    let name = this.contactRequest.value.name as string;;
    let message = this.contactRequest.value.message as string;
    this.sendEmail(email, name, message)
  }

  cleanForm() {
    this.contactRequest.reset();
  }

  sendEmail(email: string, name: string, message: string){
    let postVars = {
      email : email,
      name : name,
      message : message
    };

    const http$ = this.http.post(this.endpoint, postVars, {responseType: 'text'});

    http$.subscribe({
        next: (v) => {
          this.messageSend = true
          this.cleanForm();
        },
        error: (e) => {
          this.errorWhileSending = true; 
        }
      }
    )
        
  }

}


