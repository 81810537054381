import { AfterViewInit, Component } from '@angular/core';
import { ScrollToFragmentService } from 'src/app/services/scroll-to-fragment.service';

@Component({
  selector: 'app-grounding-penetrations',
  templateUrl: './grounding-penetrations.component.html',
  styleUrls: ['./grounding-penetrations.component.scss']
})
export class GroundingPenetrationsComponent implements AfterViewInit{

 
  constructor(private scrollService: ScrollToFragmentService){
  }
  
  ngAfterViewInit(): void {
    this.scrollService.scrollToStart();
  }

}
