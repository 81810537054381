import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeContentComponent } from './home/home-content/home-content.component';
import { ContactComponent } from './contact/contact/contact.component';
import { ConduitPipesComponent } from './offer/conduit-pipes/conduit-pipes.component';
import { CablePenetrationsComponent } from './offer/cable-penetrations/cable-penetrations.component';
import { GroundingPenetrationsComponent } from './offer/grounding-penetrations/grounding-penetrations.component';
import { BuildingPenetrationsComponent } from './offer/building-penetrations/building-penetrations.component';
import { OtherComponent } from './offer/other/other.component';
import { OwsComponent } from './info/ows/ows.component';
import { PoliticComponent } from './info/politic/politic.component';
import { AboutComponent } from './offer/about/about.component';

const routes: Routes = [
  {path: 'oferta', children:[
    {path: 'rury-przepustowe', component: ConduitPipesComponent, title: 'Rury przepustowe'},
    {path: 'przepusty-kablowe', component: CablePenetrationsComponent, title: 'Przepusty kablowe'},
    {path: 'przepusty-uziemiajace', component: GroundingPenetrationsComponent, title: 'Przepusty uziemiające'},
    {path: 'przepusty-budynkowe', component: BuildingPenetrationsComponent, title: 'Przepusty do budynków'},
    {path: 'inne', component: OtherComponent, title: 'Pozostałe rozwiązania'},
  ]},
  {path: 'o-nas', component: AboutComponent, title: 'O nas'},
  {path: 'ows', component: OwsComponent, title: ''},
  {path: 'polityka-prywatnosci', component: PoliticComponent, title: 'Polityka prywatności'},
  
  {path: 'kontakt', component: ContactComponent, title: 'Kontakt'},
  {path: 'home', component: HomeContentComponent, title: 'Przepusty kablowe'},
  {path: '', component: HomeContentComponent, title: 'Przepusty kablowe'},
  {path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
