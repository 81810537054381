import { Component, HostListener, OnInit, afterNextRender } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuToggleService } from 'src/app/services/menu-toggle.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  $isMenuOpen: Observable<boolean>;
  private screenWidth: number = 0;

  constructor(private menuToggleService: MenuToggleService){
    afterNextRender(() => {
      this.screenWidth = window.innerWidth;
      this.checkScreenWidth();
    })
    this.$isMenuOpen = this.menuToggleService.isMenuOpen$;
  }

  ngOnInit(): void {
  
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (this.screenWidth <= 576) {
      this.menuToggleService.hideMenu(); // Close the menu if the screen width is 576px or lower
    } else {
      this.menuToggleService.showMenu(); // Open the menu if the screen width is 576px or greater
    }
  }


}
