<div class="main-container">
    <div class="header-container">
        <app-top-header></app-top-header>
        <app-header></app-header>
    </div>
    <div class="content-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-container">
        <app-footer></app-footer> 
    </div>
</div>
