import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuToggleService {

  private showMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isMenuOpen$: Observable<boolean> = this.showMenuSubject.asObservable();
 
  constructor() { 
  }

  toggleMenu() {
    const currentValue = this.showMenuSubject.value;
    this.showMenuSubject.next(!currentValue);
  }

  showMenu() {
    this.showMenuSubject.next(true);
  }

  hideMenu() {
    this.showMenuSubject.next(false);
  }

}
