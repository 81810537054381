<app-home-banner></app-home-banner>
<!-- <a href="" [routerLink]="['/oferta/przepusty-kablowe']" fragment="przepusty-dachowe">ss</a> -->

<section class="about-us pt-4 pb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-8 about-paragraph">
        <h1 class="mb-4">{{'HOME_COMPONENT.HOME_BANNER.TITLE' | translate}}</h1>

        <p>
          {{'HOME_COMPONENT.HOME_BANNER.DESCRIPTION_1' | translate}}
        </p>
        <p>
          {{'HOME_COMPONENT.HOME_BANNER.DESCRIPTION_2' | translate}}
        </p>
        <p>
          {{'HOME_COMPONENT.HOME_BANNER.DESCRIPTION_3' | translate}}
        </p>
        <p>
          {{'HOME_COMPONENT.HOME_BANNER.DESCRIPTION_4' | translate}}
        </p>
        <p>
          {{'HOME_COMPONENT.HOME_BANNER.DESCRIPTION_5' | translate}}
        </p>
      </div>
      <div class="col-md-4">
        <div class="benefits-section">
          <div class="container">
            <div class="row benefit-item-row">
              <div class="benefit-item">
                <div class="benefit-item-title">
                  <h3>{{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.TITLE_1' | translate}}</h3>
                </div>
                <div class="benift-item-desc">
                  <p>
                    {{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.DESCRIPTION_1' | translate}}
                  </p>
                </div>
              </div>
              <div class="benefit-item">
                <div class="benefit-item-title">
                  <h3>{{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.TITLE_2' | translate}}</h3>
                </div>
                <div class="benift-item-desc">
                  <p>
                    {{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.DESCRIPTION_2' | translate}}
                  </p>
                </div>
              </div>
              <div class="benefit-item">
                <div class="benefit-item-title">
                  <h3>{{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.TITLE_3' | translate}}</h3>
                </div>
                <div class="benift-item-desc">
                  <p>
                    {{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.DESCRIPTION_3' | translate}}
                  </p>
                </div>
              </div>
              <div class="benefit-item">
                <div class="benefit-item-title">
                  <h3>{{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.TITLE_4' | translate}}</h3>
                </div>
                <div class="benift-item-desc">
                  <p>
                    {{'HOME_COMPONENT.BENEFITS_SECTION.BENEFITS.DESCRIPTION_4' | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="pros-section">
  <div class="container pb-5 pt-5">
    <div class="row mb-5">
      <h2 class="pros-heading">{{'HOME_COMPONENT.PROS_SECTION.PROS_HEADING' | translate}}</h2>
    </div>
    <div class="row gx-5">
      <div class="pros-item-container col-md-3">
        <div class="pros-item-border">
          <a class="pros-item-icon-container" href="">
            <img
              class="pros-item-icon"
              src="/assets/icons/contract.svg"
              alt=""
            />
          </a>
          <figcaption class="pros-item-details">
            <p class="pros-item-title">{{'HOME_COMPONENT.PROS_SECTION.PROS.TITLE_1' | translate}}</p>
            <div class="pros-item-description">
              <p>{{'HOME_COMPONENT.PROS_SECTION.PROS.DESCRIPTION_1' | translate}}</p>
            </div>
          </figcaption>
        </div>
      </div>
      <div class="pros-item-container col-md-3">
        <div class="pros-item-border">
          <a class="pros-item-icon-container" href="">
            <img
              class="pros-item-icon"
              src="/assets/icons/speed.svg"
              alt=""
            />
          </a>
          <figcaption class="pros-item-details">
            <p class="pros-item-title">{{'HOME_COMPONENT.PROS_SECTION.PROS.TITLE_2' | translate}}</p>
            <div class="pros-item-description">
              <p>
                {{'HOME_COMPONENT.PROS_SECTION.PROS.DESCRIPTION_2' | translate}}
              </p>
            </div>
          </figcaption>
        </div>
      </div>
      <div class="pros-item-container col-md-3">
        <div class="pros-item-border">
          <a class="pros-item-icon-container" href="">
            <img
              class="pros-item-icon"
              src="/assets/icons/transport.svg"
              alt=""
            />
          </a>
          <figcaption class="pros-item-details">
            <p class="pros-item-title">{{'HOME_COMPONENT.PROS_SECTION.PROS.TITLE_3' | translate}}</p>
            <div class="pros-item-description">
              <p>
                {{'HOME_COMPONENT.PROS_SECTION.PROS.DESCRIPTION_3' | translate}}
              </p>
            </div>
          </figcaption>
        </div>
      </div> 
      <div class="pros-item-container col-md-3">
        <div class="pros-item-border">
          <a class="pros-item-icon-container" href="">
            <img
              class="pros-item-icon"
              src="/assets/icons/phone-2.svg"
              alt=""
            />
          </a>
          <figcaption class="pros-item-details">
            <p class="pros-item-title">{{'HOME_COMPONENT.PROS_SECTION.PROS.TITLE_4' | translate}}</p>
            <div class="pros-item-description">
              <p>
                {{'HOME_COMPONENT.PROS_SECTION.PROS.DESCRIPTION_4' | translate}}
              </p>
            </div>
          </figcaption>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="offer mb-5 mt-5">
  <div class="container">
    <div class="offer-title mb-5">
      <h1>{{'HOME_COMPONENT.OFFER_SECTION.HEADING' | translate}}</h1>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card mb-30">
          <a class="card-img-tiles" href="/oferta/przepusty-kablowe"
          routerLink="/oferta/przepusty-kablowe">
            <div class="inner">
              <div class="main-img">
                <img
                  src="/assets/oferta/przepusty/HSI 150.webp"
                  alt="HSI 150"
                />
              </div>
              <div class="thumblist">
                <img
                  src="/assets/oferta/przepusty/HSI 150-K2-Varia.webp"
                  alt="HSI 150 K2"
                /><img
                  src="/assets/oferta/przepusty/hss150_dg.webp"
                  alt="HSS 150 dg"
                />
              </div>
            </div>
          </a>
          <div class="card-body text-center">
            <h4 class="card-title">{{'HOME_COMPONENT.OFFER_SECTION.CARDS.TITLE_1' | translate}}</h4>
            <a class="btn btn-outline-primary btn-sm" href="/oferta/przepusty-kablowe" routerLink="/oferta/przepusty-kablowe">{{'HOME_COMPONENT.OFFER_SECTION.BUTTON' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card mb-30">
          <a
            class="card-img-tiles"
            href="/oferta/rury-przepustowe"
            routerLink="/oferta/rury-przepustowe"
          >
            <div class="inner">
              <div class="main-img">
                <img src="/assets/oferta/rury/UFR100.webp" alt="UFR100" />
              </div>
              <div class="thumblist">
                <img src="/assets/oferta/rury/HRD150.webp" alt="HRD150" />
                <img src="/assets/oferta/przepusty/hsd.webp" alt="HSD" />
              </div>
            </div>
          </a>
          <div class="card-body text-center">
            <h4 class="card-title">{{'HOME_COMPONENT.OFFER_SECTION.CARDS.TITLE_2' | translate}}</h4>
            <a
              class="btn btn-outline-primary btn-sm"
              href="/oferta/rury-przepustowe"
              routerLink="/oferta/rury-przepustowe"
              >{{'HOME_COMPONENT.OFFER_SECTION.BUTTON' | translate}}</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card mb-30">
          <a class="card-img-tiles" href="/oferta/przepusty-uziemiajace" routerLink="/oferta/przepusty-uziemiajace">
            <div class="inner">
              <div class="main-img">
                <img src="/assets/oferta/uziemienia/HEA-EW.webp" alt="HEA-EW" />
              </div>
              <div class="thumblist">
                <img src="/assets/oferta/uziemienia/HEA.webp" alt="HEA" />
                <img src="/assets/oferta/uziemienia/HEA-IS.webp" alt="HEA-IS" />
              </div>
            </div>
          </a>
          <div class="card-body text-center">
            <h4 class="card-title">{{'HOME_COMPONENT.OFFER_SECTION.CARDS.TITLE_3' | translate}}</h4>
            <a class="btn btn-outline-primary btn-sm" href="/oferta/przepusty-uziemiajace" routerLink="/oferta/przepusty-uziemiajace">{{'HOME_COMPONENT.OFFER_SECTION.BUTTON' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <div class="card mb-30">
          <a class="card-img-tiles" href="/oferta/przepusty-budynkowe" routerLink="/oferta/przepusty-budynkowe">
            <div class="inner">
              <div class="main-img">
                <img
                  src="/assets/oferta/przepusty-budynkowe/AT110.webp"
                  alt="AT110"
                />
              </div>
              <div class="thumblist">
                <img
                  src="/assets/oferta/przepusty-budynkowe/ESH.webp"
                  alt="ESH"
                /><img
                  src="/assets/oferta/przepusty-budynkowe/MSH.webp"
                  alt="MSH"
                />
              </div>
            </div>
          </a>
          <div class="card-body text-center">
            <h4 class="card-title">{{'HOME_COMPONENT.OFFER_SECTION.CARDS.TITLE_4' | translate}}</h4>
            <a class="btn btn-outline-primary btn-sm" href="/oferta/przepusty-budynkowe" routerLink="/oferta/przepusty-budynkowe">{{'HOME_COMPONENT.OFFER_SECTION.BUTTON' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card mb-30">
          <a class="card-img-tiles" href="/oferta/inne" routerLink="/oferta/inne">
            <div class="inner">
              <div class="main-img">
                <img src="/assets/oferta/inne/DiSan.webp" alt="Category" />
              </div>
              <div class="thumblist">
                <img
                  src="/assets/oferta/inne/HFM.webp"
                  alt="Category"
                /><img src="/assets/oferta/inne/HFR.webp" alt="Category" />
              </div>
            </div>
          </a>
          <div class="card-body text-center">
            <h4 class="card-title">{{'HOME_COMPONENT.OFFER_SECTION.CARDS.TITLE_5' | translate}}</h4>
            <a class="btn btn-outline-primary btn-sm" href="/oferta/inne" routerLink="/oferta/inne">{{'HOME_COMPONENT.OFFER_SECTION.BUTTON' | translate}}</a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>
