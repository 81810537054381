<div class="container flag-container mt-2">
  <span (click)="changeLanguage('pl')"><img src="/assets/icons/pl.svg" alt="pl"></span>
  <span (click)="changeLanguage('cz')"><img src="/assets/icons/cz.svg" alt="cz"></span>
</div>
<div class="container mb-4 mt-2" id="start">
  <div class="row logo-row">
    <div class="col-md-4 col-sm-8 logo" routerLink="./">
      <img src="assets/logo-transformed32.webp" class="img-fluid" alt="" />
    </div>
    <div class="col-md-8 contact-info-container">
      <div class="row contact-info-row">
        <div class="col">
          <div class="contact-info-icon">
            <img src="assets/icons/location.svg" alt="phone icon" />
          </div>
          <div class="contact-info-details">
            ul. Głowackiego 60a
            <br />
            44-100 Gliwice
          </div>
        </div>
        <div class="col">
          <div class="contact-info-icon">
            <img src="assets/icons/phone.svg" alt="phone icon" />
          </div>
          <div class="contact-info-details">
            {{'TOP_HEADER.TELEPHONE' | translate}}
            <br />
            <a href="tel:+48603490649" class="col-md-4">+48 603 490 649</a>
          </div>
        </div>
        <div class="col">
          <div class="contact-info-icon">
            <img src="assets/icons/email.svg" alt="phone icon" />
          </div>
          <div class="contact-info-details">
            {{'TOP_HEADER.EMAIL' | translate}}
            <br />
            <a href="mailto:oferty@przepusty-kablowe.pl">oferty&#64;przepusty-kablowe.pl</a>
          </div>
        </div>
      </div>
    </div>
    <div class="toggle-menu col">
      
      <img src="assets/icons/menu.svg" alt="menu icon" (click)="toggle()" />
    </div>
  </div>
</div>
