<div class="container article-container">
    <div class="col-md-10">
        <article>
            <div class="title-container mb-4">
                <div class="spacer"></div>
                <div class="title-text">
                    <h1>{{'CABLE_PENETRATIONS_COMPONENT.TITLE' | translate}}</h1>
                </div>
                <div class="spacer"></div>
            </div>

            <div class="mb-4">
                <p>
                    {{'CABLE_PENETRATIONS_COMPONENT.DESCRIPTION' | translate}}
                </p>
            </div>

            <div class="article-paragraph row mb-4 img-right">
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_TITLE_1' | translate}}</h2>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_1_1' | translate}}
                    </p>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_1_2' | translate}}
                    </p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/HSI 150.webp" alt="Jednostronny przepust kablowy" class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_IMAGE_1' | translate}}
                        </p>
                        <p>
                            HSI150 K/X
                        </p>
                    </span>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/HSI 150-K2-Varia.webp" alt="Dwustronny przepust kablowy"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_IMAGE_2' | translate}}
                        </p>
                        <p>
                            HSI150 K2 Varia
                        </p>
                    </span>
                </div>
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_TITLE_2' | translate}}</h2>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_2' | translate}}
                    </p>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left" id="przepusty-dachowe">
                <div class="article-paragraph-text col-md-7">

                    <h2>{{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_TITLE_3' | translate}}</h2>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_3' | translate}}
                    </p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/HRD-FUFF.webp" alt="Rura przepustowa z podwójnym kołnierzem"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_IMAGE_3' | translate}}
                        </p>
                        <p>
                            2FLFE DIN18531
                        </p>
                    </span>
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/hsd.webp" alt="Standardowy gumowy wkład uszczelniający" class="img-fluid">

                        <span class="img-desc">
                            <p>
                                {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_IMAGE_4' | translate}}
                            </p>
                            <p>
                                HSD SSG
                            </p>
                        </span>
                    </div>
                </div>
                <div class="article-paragraph-text col-md-7">

                    <h2>{{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_TITLE_4' | translate}}</h2>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_4' | translate}}
                    </p>


                </div>
            </div>

            <div class="article-paragraph row mb-4 img-right">

                <div class="article-paragraph-text col-md-7">

                    <h2>{{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_TITLE_5' | translate}}</h2>
                    <p>
                        {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_DESCRIPTION_5' | translate}}
                    </p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/hss150_dg.webp" alt="Zestaw do ochrony przeciwpożarowej"
                            class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CABLE_PENETRATIONS_COMPONENT.PARAGRAPH_IMAGE_5' | translate}}
                        </p>
                        <p>

                        </p>
                    </span>
                </div>
            </div>


            <app-catlog-link></app-catlog-link>

        </article>
    </div>
</div>