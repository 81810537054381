import { Component } from '@angular/core';

@Component({
  selector: 'app-ows',
  templateUrl: './ows.component.html',
  styleUrls: ['./ows.component.scss']
})
export class OwsComponent {

  
}
