<div class="container about-section mt-5 mb-5">

    <div class="col-md-8">
        <h1>
            {{'ABOUT_COMPONENT.ABOUT_HEADING' | translate}}
        </h1>
        <p class="mt-4">
            {{'ABOUT_COMPONENT.ABOUT_PARAGRAPH_1' | translate}}
        </p>
        <p>
            {{'ABOUT_COMPONENT.ABOUT_PARAGRAPH_2' | translate}}
        </p>
        <p>
            {{'ABOUT_COMPONENT.ABOUT_PARAGRAPH_3' | translate}}
        </p>
        <p>
            {{'ABOUT_COMPONENT.ABOUT_PARAGRAPH_4' | translate}} <a href="https://www.armes.pl">https://www.armes.pl</a>
        </p>
    </div>

</div>