<div class="container article-container">
    <div class="col-md-10">
        <article>
            <div class="title-container mb-4">
                <div class="spacer"></div>
                <div class="title-text">
                    <h1>{{'CONDUIT_PIPES_COMPONENT.TITLE' | translate}}</h1>
                </div>
                <div class="spacer"></div>
            </div>
            <div class="mb-4">
                <p>{{'CONDUIT_PIPES_COMPONENT.DESCRIPTION' | translate}}</p>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_TITLE_1' | translate}}</h2> 
                    <p>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_DESCRIPTION_1' | translate}}</p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/rury/UFR100.webp" alt="Uniwersalna rura okładzinowa" class="img-fluid">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_IMAGE_1' | translate}}
                        </p>
                        <p>
                            UFR
                        </p>
                    </span>
                </div>
            </div>
            <div class="article-paragraph row mb-4 img-left">
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/rury/HRD150.webp" alt="Rura przepustowa z kołnierzem ze stali nierdzewnej" class="img-fluid mirror">
                    </div>
                    <span class="img-desc">
                        <p>
                            {{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_IMAGE_2' | translate}} 
                        </p>
                        <p>
                            FLFA DIN18531 A2
                        </p>
                    </span>
                </div>
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_TITLE_2' | translate}}</h2> 
                    <p>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_DESCRIPTION_2' | translate}}</p>
                    
                </div>
            </div>

            <div class="article-paragraph row mb-4 img-left">
                <div class="article-paragraph-text col-md-7">
                    <h2>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_TITLE_3' | translate}}</h2> 
                    <p>{{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_DESCRIPTION_3' | translate}}</p>
                </div>
                <div class="article-image col-md-5">
                    <div class="img-wrapper">
                        <img src="assets/oferta/przepusty/hsd.webp" alt="Standardowy gumowy wkład uszczelniający" class="img-fluid">

                        <span class="img-desc">
                            <p>
                                {{'CONDUIT_PIPES_COMPONENT.PARAGRAPH_IMAGE_3' | translate}}
                            </p>
                            <p>
                                HSD SSG
                            </p>
                        </span>
                    </div>
                </div>             
            </div>

            <app-catlog-link></app-catlog-link>
        
        </article>
    </div>
</div>