<div class="header">
    <div class="container header-menu pt-2 pb-2 " [hidden]="!($isMenuOpen | async)">
        <a href="/o-nas" routerLink="/o-nas" routerLinkActive="active">{{'HEADER.NAV_TITLE.1' | translate}}</a>
        <a href="/oferta/przepusty-kablowe" routerLink="/oferta/przepusty-kablowe" routerLinkActive="active">{{'HEADER.NAV_TITLE.2' | translate}}</a>
        <a href="/oferta/rury-przepustowe" routerLink="/oferta/rury-przepustowe" routerLinkActive="active">{{'HEADER.NAV_TITLE.3' | translate}}</a>
        <a href="/oferta/przepusty-uziemiajace" routerLink="/oferta/przepusty-uziemiajace" routerLinkActive="active">{{'HEADER.NAV_TITLE.4' | translate}}</a>
        <a href="/oferta/przepusty-budynkowe" routerLink="/oferta/przepusty-budynkowe" routerLinkActive="active">{{'HEADER.NAV_TITLE.5' | translate}}</a>
        <a href="/oferta/inne" routerLink="/oferta/inne" routerLinkActive="active">{{'HEADER.NAV_TITLE.6' | translate}}</a>
        <a href="/kontakt" routerLink="/kontakt" routerLinkActive="active">{{'HEADER.NAV_TITLE.7' | translate}}</a>
    </div>
</div>