import { AfterViewInit, Component } from '@angular/core';
import { ScrollToFragmentService } from 'src/app/services/scroll-to-fragment.service';

@Component({
  selector: 'app-conduit-pipes',
  templateUrl: './conduit-pipes.component.html',
  styleUrls: ['./conduit-pipes.component.scss']
})
export class ConduitPipesComponent implements AfterViewInit{

 
  constructor(private scrollService: ScrollToFragmentService){
    
  }

  ngAfterViewInit(): void {
    this.scrollService.scrollToStart();
  }

}
