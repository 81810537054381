<div class="container article-container">
  <div class="col-md-10">
    <article>
      <div class="title-container mb-4">
        <div class="spacer"></div>
        <div class="title-text">
          <h1>{{'OTHER_COMPONENT.TITLE' | translate}}</h1>
        </div>
        <div class="spacer"></div>
      </div>

      <div class="mb-4">
        <p>
          {{'OTHER_COMPONENT.DESCRIPTION' | translate}}
        </p>
      </div>

      <div class="article-paragraph row mb-4 img-right">
        <div class="article-paragraph-text col-md-7">
          <h2>{{'OTHER_COMPONENT.PARAGRAPH_TITLE_1' | translate}}
          </h2>
          <p>
            {{'OTHER_COMPONENT.PARAGRAPH_DESCRIPTION_1' | translate}}
          </p>
        </div>
        <div class="article-image col-md-5">
          <div class="img-wrapper">
            <img
              src="assets/oferta/inne/DiSan.webp"
              alt="Piasek do uszczelniania"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'OTHER_COMPONENT.PARAGRAPH_IMAGE_1' | translate}}</p>
            <p>DiSan</p>
          </span>
        </div>
      </div>

      <div class="article-paragraph row mb-4 img-left">
        <div class="article-image col-md-5">
          <div class="img-wrapper">
            <img
              src="assets/oferta/inne/HFM.webp"
              alt="Manszeta do foliiy"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'OTHER_COMPONENT.PARAGRAPH_IMAGE_2' | translate}}</p>
            <p>HFM </p>
          </span>
        </div>
        <div class="article-paragraph-text col-md-7">
          <h2>{{'OTHER_COMPONENT.PARAGRAPH_TITLE_2' | translate}}
          </h2>
          <p>
            {{'OTHER_COMPONENT.PARAGRAPH_DESCRIPTION_2' | translate}}
          </p>
        </div>
      </div>

      <div class="article-paragraph row mb-4 img-right">
        <div class="article-paragraph-text col-md-7">
          <h2>{{'OTHER_COMPONENT.PARAGRAPH_TITLE_3' | translate}}
          </h2>
          <p>
            {{'OTHER_COMPONENT.PARAGRAPH_DESCRIPTION_3' | translate}}
          </p>
        </div>
        <div class="article-image col-md-5">
          <div class="img-wrapper">
            <img
              src="assets/oferta/inne/ULF.webp"
              alt="Uniwersalny fundament kolumny ładowania"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'OTHER_COMPONENT.PARAGRAPH_IMAGE_3' | translate}}</p>
            <p>ULF</p>
          </span>
        </div>
      </div>

      <div class="article-paragraph row mb-4 img-left">
        <div class="article-image col-md-5">
          <div class="img-wrapper">
            <img
              src="assets/oferta/inne/HFR.webp"
              alt="Rura fundamentowa"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'OTHER_COMPONENT.PARAGRAPH_IMAGE_4' | translate}}</p>
            <p>HFR</p>
          </span>
        </div>
        <div class="article-paragraph-text col-md-7">
          <h2>{{'OTHER_COMPONENT.PARAGRAPH_TITLE_4' | translate}}
          </h2>
          <p>
            {{'OTHER_COMPONENT.PARAGRAPH_DESCRIPTION_4' | translate}}
          </p>
        </div>
      </div>
      <app-catlog-link></app-catlog-link>
    </article>
  </div>
</div>
