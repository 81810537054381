<div class="container statute-container  mt-5 mb-5">
    <div class="col-md-10">
        <h1>Polityka Prywatności</h1>
        <div class="statute-point">
            <h2>1. Informacje ogólne</h2>
            <p>Firma Handlowo Usługowa Armes, z siedzibą przy ulicy Bartosza Głowackiego 60a, 44-100 Gliwice, NIP:
                9690181749, REGON: 240423507 (dalej zwana "Firmą") jest zobowiązana do ochrony prywatności użytkowników
                swojej strony internetowej.
                <br>

                Niniejsza Polityka Prywatności określa, jakie dane osobowe są zbierane, w jaki sposób są przetwarzane
                oraz jakie prawa przysługują użytkownikom w związku z przetwarzaniem ich danych.
            </p>
        </div>
        <div class="statute-point">
            <h2>2. Rodzaje zbieranych danych</h2>
            <p>Firma zbiera następujące rodzaje danych osobowych:

                Adresy email,
                Imię i nazwisko użytkownika,
                Treść zapytania pochodzące z formularza na potrzeby zapytania ofertowego.</p>
        </div>
        <div class="statute-point">
            <h2>3. Cel zbierania danych</h2>
            <p>Dane osobowe są zbierane w celu udzielenia odpowiedzi na zapytania ofertowe oraz kontaktu z użytkownikami
                w związku z tymi zapytaniami.</p>
        </div>
        <div class="statute-point">
            <h2>4. Podstawa prawna przetwarzania danych</h2>
            <p>Podstawą prawną przetwarzania danych osobowych jest zgoda użytkownika wyrażona poprzez wypełnienie
                formularza na stronie internetowej.</p>
        </div>
        <div class="statute-point">
            <h2>5. Prawa użytkowników</h2>
            <p>Użytkownicy posiadają następujące prawa w zakresie danych osobowych:

                Prawo dostępu do danych,
                Prawo do sprostowania danych,
                Prawo do usunięcia danych,
                Prawo do przenoszenia danych,
                Prawo do sprzeciwu wobec przetwarzania danych.</p>
        </div>
        <div class="statute-point">
            <h2>6. Udostępnianie danych</h2>
            <p>Dane osobowe użytkowników nie są udostępniane osobom trzecim i są przetwarzane wyłącznie przez
                upoważnionych pracowników Firmy.</p>
        </div>
        <div class="statute-point">
            <h2>7. Bezpieczeństwo danych</h2>
            <p>Firma zapewnia odpowiednie środki bezpieczeństwa w celu ochrony danych osobowych przed utratą,
                nieuprawnionym dostępem i innymi zagrożeniami.</p>

        </div>
        <div class="statute-point">
            <h2>8. Cookies i śledzenie</h2>
            <p>Nasza strona internetowa nie korzysta z plików cookies.</p>
        </div>
        <div class="statute-point">
            <h2>9. Kontakt z administratorem danych</h2>
            <p>W przypadku pytań lub w celu realizacji praw związanych z danymi osobowymi, prosimy o kontakt z
                Administratorem Danych Osobowych pod adresem email: oferty&#64;przepusty-kablowe.pl.</p>
        </div>
        <div class="statute-point">
            <h2>10. Aktualizacje polityki</h2>
            <p>Niniejsza Polityka Prywatności może być okresowo aktualizowana. Użytkownicy zostaną poinformowani o
                ewentualnych zmianach.</p>
        </div>
        <div class="statute-modified-date">
            Ostatnia aktualizacja: [05.10.2023]
        </div>
    </div>
</div>