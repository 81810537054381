<div class="container article-container">
  <div class="col-md-10">
    <article>
      <div class="title-container mb-4">
        <div class="spacer"></div>
        <div class="title-text">
          <h1>{{'GROUNDING_PENTRATIONS_COMPONENT.TITLE' | translate}}</h1>
        </div>
        <div class="spacer"></div>
      </div>

      <div class="mb-4">
        <div>
          <p>
            {{'GROUNDING_PENTRATIONS_COMPONENT.DESCRIPTION' | translate}}
          </p>
          <p>{{'GROUNDING_PENTRATIONS_COMPONENT.GROUNDING_PENETRATIONS_PRODUCTS.TITLE' | translate}}</p>
          <ul>
            <li>
              {{'GROUNDING_PENTRATIONS_COMPONENT.GROUNDING_PENETRATIONS_PRODUCTS.ITEM_1' | translate}}
            </li>
            <li>
              {{'GROUNDING_PENTRATIONS_COMPONENT.GROUNDING_PENETRATIONS_PRODUCTS.ITEM_2' | translate}}
            </li>
            <li>
              {{'GROUNDING_PENTRATIONS_COMPONENT.GROUNDING_PENETRATIONS_PRODUCTS.ITEM_3' | translate}}
            </li>
          </ul>
          <p>
            {{'GROUNDING_PENTRATIONS_COMPONENT.FOOTER' | translate}}
          </p>
        </div>
      </div>

      <div class="article-paragraph row mb-4 img-right">
        <div class="article-image col-md-4">
          <div class="img-wrapper">
            <img
              src="assets/oferta/uziemienia/HEA.webp"
              alt="Przepust uziemiający"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'GROUNDING_PENTRATIONS_COMPONENT.IMAGE_1_DESCRIPTION' | translate}}</p>
            <p>HEA</p>
          </span>
        </div>
        <div class="article-image col-md-4">
          <div class="img-wrapper">
            <img
              src="assets/oferta/uziemienia/HEA-EW.webp"
              alt="Przepust uziemiający"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'GROUNDING_PENTRATIONS_COMPONENT.IMAGE_2_DESCRIPTION' | translate}}</p>
            <p>HEA EW</p>
          </span>
        </div>
        <div class="article-image col-md-4">
          <div class="img-wrapper">
            <img
              src="assets/oferta/uziemienia/HEA-IS.webp"
              alt="Izolowany przepust uziemiający"
              class="img-fluid"
            />
          </div>
          <span class="img-desc">
            <p>{{'GROUNDING_PENTRATIONS_COMPONENT.IMAGE_3_DESCRIPTION' | translate}}</p>
            <p>HEA IS</p>
          </span>
        </div>
      </div>

      <app-catlog-link></app-catlog-link>
    </article>
  </div>
</div>
