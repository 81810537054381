import { AfterViewInit, Component,  } from '@angular/core';
import { ScrollToFragmentService } from 'src/app/services/scroll-to-fragment.service';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss']
})
export class OtherComponent implements AfterViewInit{

 
  constructor(private scrollService: ScrollToFragmentService){
   
  }
  
  ngAfterViewInit(): void {
    this.scrollService.scrollToStart();
  }

}
