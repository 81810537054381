import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuToggleService } from 'src/app/services/menu-toggle.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent {


  constructor(private menuToggleService: MenuToggleService, private translate: TranslateService){

  }


  toggle(){
    this.menuToggleService.toggleMenu();
  }

  changeLanguage(language: string){
    document.documentElement.lang = language;
    this.translate.use(language);
  }

}
