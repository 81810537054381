<div class="sucess-message mb-4" [hidden]="!messageSend">
    <span class="badge bg-success">Pomyślnie wysłano wiadomość. <br><br> Odpowiemy najszybciej jak to możliwe.</span>
</div>
<div class="sucess-message mb-4" [hidden]="!errorWhileSending">
    <span class="badge bg-danger">Wystąpił bład podczas wysyłania wiadomości. <br><br> Skontaktuj się z nami bezpośrednio pod: <a href="mailto:oferty@przepusty-kablowe.pl" style="color: white;">oferty&#64;przepusty-kablowe.pl</a></span>
</div>
<form [formGroup]="contactRequest" (ngSubmit)="onSubmit()">
    <div class="mb-3">
        <label for="email" class="form-label">Adres email:</label>
        <input type="email" class="form-control" id="email" placeholder="&#xf0e0; adres@email.pl" formControlName="email" style="font-family:Arial, FontAwesome">
        <div class="invalid-input" *ngIf="contactRequest.get('email')!.invalid && contactRequest.get('email')!.touched">
            Proszę podać poprawny email.
        </div>
    </div>
    <div class="mb-3">
        <label for="name" class="form-label">Imię i nazwisko:</label>
        <input type="name" class="form-control" id="name" placeholder="&#xf007; Imię i Nazwisko" formControlName="name" style="font-family:Arial, FontAwesome">
        <div class="invalid-input" *ngIf="contactRequest.get('name')!.invalid && contactRequest.get('name')!.touched">
            Proszę podaj imię i nazwisko.
        </div>
    </div>


    <div class="mb-3">
        <label for="message" class="form-label">Treść wiadomości:</label>
        <textarea class="form-control" id="message" rows="5" formControlName="message" placeholder="&#xf075; Treść zapytania ..." style="font-family:Arial, FontAwesome"></textarea>
        <div class="invalid-input" *ngIf="contactRequest.get('message')!.invalid && contactRequest.get('message')!.touched">
            Proszę podać treść wiadomości. 
        </div>
    </div>

    <div class="mb-3">
        <input class="form-check-input" type="checkbox" value="" id="statutes" formControlName="statutes" style="margin-right: 0.5rem;">
        <label class="form-check-label" for="statutes">
            Akceptuję <a href="/ows" routerLink="/ows">ogólne warunki sprzedaży i dostawy</a> i <a href="/polityka-prywatnosci" routerLink="/polityka-prywatnosci">politykę prywatności</a> 
        </label>
    </div>
    
    <div class="mb-3 submit-form">     
        <p [hidden]="contactRequest.valid">{{'CONTACT_MAILFORM_COMPONENT.FILL_ALL_FIELDS_ERROR_MESSAGE' | translate}}</p>
        <button type="submit" class="btn btn-primary" [disabled]="!contactRequest.valid">{{'CONTACT_MAILFORM_COMPONENT.SUBMIT_BUTTON_LABEL' | translate}}</button>
    </div>
</form>