import { Component } from '@angular/core';

@Component({
  selector: 'app-catlog-link',
  templateUrl: './catlog-link.component.html',
  styleUrls: ['./catlog-link.component.scss']
})
export class CatlogLinkComponent {

}
