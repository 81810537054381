<div class="banner-wrapper">
  <div class="banner"></div>
  <div class="carousel-wrapper-conatiner">
    <div class="banner-info">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/home/back.png" alt="" class="slide-custom" />
          </div>
          <div class="carousel-caption">
            <h3>{{ 'HOME_BANNER.SLIDE_1_TITLE' | translate }}</h3>
            <p>{{ 'HOME_BANNER.SLIDE_1_DESCRIPTION' | translate }}</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/home/back.png" alt="" class="slide-custom" />
          </div>
          <div class="carousel-caption">
            <h3>{{ 'HOME_BANNER.SLIDE_2_TITLE' | translate }}</h3>
            <p>{{ 'HOME_BANNER.SLIDE_2_DESCRIPTION' | translate }}</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/home/back.png" alt="" class="slide-custom" />
          </div>
          <div class="carousel-caption">
            <h3>{{ 'HOME_BANNER.SLIDE_3_TITLE' | translate }}</h3>
            <p>{{ 'HOME_BANNER.SLIDE_3_DESCRIPTION' | translate }}</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/home/back.png" alt="" class="slide-custom" />
          </div>
          <div class="carousel-caption">
            <h3>{{ 'HOME_BANNER.SLIDE_4_TITLE' | translate }}</h3>
            <p>{{ 'HOME_BANNER.SLIDE_4_DESCRIPTION' | translate }}</p>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
