import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

   // Mock catalog data
   private catalogData = [
    { name: 'HSI150 1x1 GSM110 Varia/200-260', index: 2120206120 },
    { name: 'HSI90 1x1 GSM110 Varia/200-260', index: 2120206121 },
    { name: 'Catalog Item 3', index: 2120206122 },
    { name: 'Catalog Item 4', index: 2120206123 },
  ];

  getCatalogData() {
    return this.catalogData;
  }
  
}
