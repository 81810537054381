import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header/header.component';
import { TopHeaderComponent } from './header/top-header/top-header.component';
import { HomeBannerComponent } from './home/home-banner/home-banner.component';
import { HomeContentComponent } from './home/home-content/home-content.component';
import { FooterComponent } from './footer/footer/footer.component';
import { ContactComponent } from './contact/contact/contact.component';
import { ContactMailformComponent } from './contact/contact-mailform/contact-mailform.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CatalogService } from './services/catalog.service';
import { ConduitPipesComponent } from './offer/conduit-pipes/conduit-pipes.component';
import { CablePenetrationsComponent } from './offer/cable-penetrations/cable-penetrations.component';
import { GroundingPenetrationsComponent } from './offer/grounding-penetrations/grounding-penetrations.component';
import { BuildingPenetrationsComponent } from './offer/building-penetrations/building-penetrations.component';
import { OtherComponent } from './offer/other/other.component';
import { OwsComponent } from './info/ows/ows.component';
import { PoliticComponent } from './info/politic/politic.component';
import { CatlogLinkComponent } from './offer/catlog-link/catlog-link.component';
import { AboutComponent } from './offer/about/about.component';
import { ScrollToFragmentService } from './services/scroll-to-fragment.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TopHeaderComponent,
    HomeBannerComponent,
    HomeContentComponent,
    FooterComponent,
    ContactComponent,
    ContactMailformComponent,
    ConduitPipesComponent,
    CablePenetrationsComponent,
    GroundingPenetrationsComponent,
    BuildingPenetrationsComponent,
    OtherComponent,
    OwsComponent,
    PoliticComponent,
    CatlogLinkComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }, defaultLanguage: 'pl'
    })
  ],
  providers: [CatalogService, ScrollToFragmentService, TranslateService, provideClientHydration(), provideHttpClient(withFetch())],
  bootstrap: [AppComponent]
})
export class AppModule {  }
